@use "../theme/shared-var.scss";

@media only screen and (min-width: shared-var.$primary-desktop-width) {
  ion-modal.gd-comp-modal.gd-modal-fit::part(content),
  ion-modal.gd-comp-modal.gd-modal-fit .ion-page {
    width: 400px !important;
    border-radius: 6px;
  }
  ion-modal.gd-comp-modal.gd-modal-standard::part(content),
  ion-modal.gd-comp-modal.gd-modal-standard .ion-page {
    width: 1100px !important;
    border-radius: 6px;
  }
  ion-modal.gd-comp-modal.gd-modal-expandable::part(content),
  ion-modal.gd-comp-modal.gd-modal-expandable .ion-page {
    width: 1100px !important;
    height: 100% !important;
    border-radius: 6px;
  }
}

@media only screen and (max-width: shared-var.$primary-mobile-width) {
  ion-modal.gd-comp-modal.gd-modal-fit::part(content),
  ion-modal.gd-comp-modal.gd-modal-fit .ion-page {
    width: unset !important;
    border-radius: 6px;
  }
  ion-modal.gd-comp-modal.gd-modal-standard::part(content),
  ion-modal.gd-comp-modal.gd-modal-standard .ion-page {
    width: 300px !important;
    border-radius: 6px;
  }
  ion-modal.gd-comp-modal.gd-modal-expandable::part(content),
  ion-modal.gd-comp-modal.gd-modal-expandable .ion-page {
    width: unset !important;
    height: 100% !important;
  }
}
