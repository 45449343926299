ion-input {
  --padding-start: 10px!important; /* Adjust padding for the input field */
  --padding-end: 10px!important;
}

ion-textarea {
  --padding-start: 10px!important; /* Adjust padding for the input field */
  --padding-end: 10px!important;
}

ion-input input::placeholder {
  padding-left: 10px; /* Padding for the placeholder */
  color: #999; /* Optional: change placeholder color */
}

ion-textarea textarea::placeholder {
  padding-left: 10px; /* Padding for the placeholder */
  color: #999; /* Optional: change placeholder color */
}
