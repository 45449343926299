.w-0 {
  width: 0;
}
.w-px {
  width: 1px;
}
.w-1 {
  width: 0.25rem;
}
.w-2 {
  width: 0.5rem;
}
.w-3 {
  width: 0.75rem;
}
.w-4 {
  width: 1rem;
}
.w-5 {
  width: 1.25rem;
}
.w-6 {
  width: 1.5rem;
}
.w-7 {
  width: 1.75rem;
}
.w-8 {
  width: 2rem;
}
.w-9 {
  width: 2.25rem;
}
.w-10 {
  width: 2.5rem;
}
.w-11 {
  width: 2.75rem;
}
.w-12 {
  width: 3rem;
}
.w-14 {
  width: 3.5rem;
}
.w-16 {
  width: 4rem;
}
.w-20 {
  width: 5rem;
}
.w-24 {
  width: 6rem;
}
.w-28 {
  width: 7rem;
}
.w-32 {
  width: 8rem;
}
.w-36 {
  width: 9rem;
}
.w-40 {
  width: 10rem;
}
.w-44 {
  width: 11rem;
}
.w-48 {
  width: 12rem;
}
.w-52 {
  width: 13rem;
}
.w-56 {
  width: 14rem;
}
.w-60 {
  width: 15rem;
}
.w-64 {
  width: 16rem;
}
.w-72 {
  width: 18rem;
}
.w-80 {
  width: 20rem;
}
.w-96 {
  width: 24rem;
}
.h-0 {
  height: 0px;
}
.h-px {
  height: 1px;
}
.h-0dot5 {
  height: 0.125rem;
}
.h-1 {
  height: 0.25rem;
}
.h-1dot5 {
  height: 0.375rem;
}
.h-2 {
  height: 0.5rem;
}
.h-2dot5 {
  height: 0.625rem;
}
.h-3 {
  height: 0.75rem;
}
.h-3dot5 {
  height: 0.875rem;
}
.h-4 {
  height: 1rem;
}
.h-5 {
  height: 1.25rem;
}
.h-6 {
  height: 1.5rem;
}
.h-7 {
  height: 1.75rem;
}
.h-8 {
  height: 2rem;
}
.h-9 {
  height: 2.25rem;
}
.h-10 {
  height: 2.5rem;
}
.h-11 {
  height: 2.75rem;
}
.h-12 {
  height: 3rem;
}
.h-14 {
  height: 3.5rem;
}
.h-16 {
  height: 4rem;
}
.h-20 {
  height: 5rem;
}
.h-24 {
  height: 6rem;
}
.h-28 {
  height: 7rem;
}
.h-32 {
  height: 8rem;
}
.h-36 {
  height: 9rem;
}
.h-40 {
  height: 10rem;
}
.h-44 {
  height: 11rem;
}
.h-48 {
  height: 12rem;
}
.h-52 {
  height: 13rem;
}
.h-56 {
  height: 14rem;
}
.h-60 {
  height: 15rem;
}
.h-64 {
  height: 16rem;
}
.h-72 {
  height: 18rem;
}
.h-80 {
  height: 20rem;
}
.h-96 {
  height: 24rem;
}
.h-auto {
  height: auto;
}
.h-1div2 {
  height: 50%;
}
.h-1div3 {
  height: 33.333333%;
}
.h-2div3 {
  height: 66.666667%;
}
.h-1div4 {
  height: 25%;
}
.h-2div4 {
  height: 50%;
}
.h-3div4 {
  height: 75%;
}
.h-1div5 {
  height: 20%;
}
.h-2div5 {
  height: 40%;
}
.h-3div5 {
  height: 60%;
}
.h-4div5 {
  height: 80%;
}
.h-1div6 {
  height: 16.666667%;
}
.h-2div6 {
  height: 33.333333%;
}
.h-3div6 {
  height: 50%;
}
.h-4div6 {
  height: 66.666667%;
}
.h-5div6 {
  height: 83.333333%;
}
.h-full {
  height: 100%;
}
.h-screen {
  height: 100vh;
}

$primary-mobile-width: 480px;
$primary-tablet-width: 768px; // 1 kolom
$primary-desktop-width: 1280px; // 3 kolom
$secondary-mobile-width: 360px; // [homepage] favorite menu 4 icon iphone 5s
$secondary-tablet-width: 600px; //  [gd-upload2-multiple, sf-upload2, sf-upload-image]
$secondary-desktop-width: 1024px; // [homepage] 2 kolom konten
$secondary-sidemenu-collapse: 1420px; // [AppMenuPage]sidemenu collapse
$terniary-desktop-width: 1366px; // login-demo

@mixin position-setting($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  left: $left;
  right: $right;
  bottom: $bottom;
}

@mixin shape-box($width: unset, $height: unset, $radius: 0) {
  width: $width;
  height: $height;
  border-radius: $radius;
}

@mixin font-setting($size, $color: unset, $weight: normal) {
  font-size: $size;
  color: $color;
  font-weight: $weight;
}

.mobile-container {
  max-width: 430px !important;
  margin: 0 auto;
}

.mobile-footer {
  position: sticky;
  bottom: 0;
  @media screen and (min-width: 768px) {
    width: calc(100% - 18px);
  }
  --ion-toolbar-background: white !important;
  background-color: white !important;

  ion-toolbar {
    border-top: 1px solid lightgrey;
    max-width: 430px !important;
    --background: var(--gdx-color-bgcard);
    margin: 0 auto;
  }
}

.container {
  padding: 16px;
}
