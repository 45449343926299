.border-0 {
  border-width: 0px;
  border-style: solid;
  border-color: var(--gdx-color-outline);
}
.border-2 {
  border-width: 2px;
  border-style: solid;
  border-color: var(--gdx-color-outline);
}
.border-4 {
  border-width: 4px;
  border-style: solid;
  border-color: var(--gdx-color-outline);
}
.border-8 {
  border-width: 8px;
  border-style: solid;
  border-color: var(--gdx-color-outline);
}
.border {
  border-width: 1px;
  border-style: solid;
  border-color: var(--gdx-color-outline);
}

.rounded-none {
  border-radius: 0px;
}
.rounded-sm {
  border-radius: 0.125rem;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-md {
  border-radius: 0.375rem;
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded-xl {
  border-radius: 0.75rem;
}
.rounded-2xl {
  border-radius: 1rem;
}
.rounded-3xl {
  border-radius: 1.5rem;
}
.rounded-full {
  border-radius: 9999px;
}
.divide-y {
  height: 1px;
  background-color: var(--ion-color-light);
}
