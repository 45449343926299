/* You can add global styles to this file, and also import other style files */
@import "theme/borders.scss";
@import "theme/effects.scss";
@import "theme/flexbox-grid.scss";
@import "theme/interactivity.scss";
@import "theme/sizing.scss";
@import "theme/spacing.scss";
@import "theme/transform.scss";
@import "theme/typography.scss";
@import "theme/button.scss";
@import "theme/forms.scss";

@import "~@ionic/angular/css/core.css";

// MODAL CUSTOM
@import "theme/standard-modal.scss";

// CSS For Editor
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// @import "~@greatday/components/style/global.scss";

.xng-breadcrumb-item {
  color: var(--ion-color-medium);
  text-transform: capitalize;
}

.xng-breadcrumb-trail,
.xng-breadcrumb-separator {
  color: var(--ion-color-medium) !important;
  font-weight: bold;
}

.xng-breadcrumb-separator {
  justify-content: center;
  align-items: center;
  margin-right: 5px !important;
  margin-left: 5px !important;
}

.xng-breadcrumb-link {
  border-bottom: 1px solid transparent;
  text-decoration: none !important;
}

.xng-breadcrumb-link:hover {
  text-decoration: none !important;
  border-bottom: 1px solid var(--gd-comp-border-color-1);
}

ion-content {
  --background: var(--gdx-color-bgfloor);

  .container {
    padding: 16px;
  }
}

app-navbar {
  .breadcrumb-header {
    display: none !important;
  }
}

app-navbar.show-breadcrumb {
  .breadcrumb-header {
    display: flex !important;
  }
}

app-navbar.full-breadcrumb.show-breadcrumb {
  .breadcrumb-header {
    width: calc(100vw - 50px) !important;
  }
}

hr {
  border-bottom: 1px solid var(--gdx-color-outline) !important;
}

.card {
  border-radius: 8px;
  background-color: white;
  width: 100%;
}

ion-modal.gd-comp-modal::part(content) {
  overflow: unset;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 90vh;
  max-height: 900px;
  width: 90vw;
  max-width: 1000px;
}

gd-comp-button gd-comp-icon {
  margin-right: 4px;
}

// STYLE GLOBAL TABLE COMPONENT
.table-layout {
  width: 100%;
}

.pagination-section {
  background: var(--gdx-color-bgfloor) !important;
  padding: 16px;

  ion-label {
    width: 100%;
  }
}

// Global Style Search


[no-margin] {
  margin: 0 !important;
}

.company-popover::part(content) {
  width: 80vw;
  height: 90vh;
  max-width: 720px;
  max-height: 588px;
  position: absolute;
  // left: 0% !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  transition: all 1s ease;
}

ion-modal.gd-comp-modal.mini-modal::part(content),
ion-modal.gd-comp-modal.mini-modal .ion-page {
  // width: 90% !important;
  max-width: 400px !important;
  max-height: 514px !important;
  height: 70vh;
  min-height: unset !important;
}

gd-comp-card {

  h6,
  ion-label,
  .text-lg {
    color: black;
  }
}

.datepicker-ionic::part(content) {
  width: 350px !important;
}
