.transition-1s {
  transition: all 0.7s;
}

.rotate180 {
  transform: rotate(180deg);
}

.rotate0 {
  transform: rotate(0deg);
}

.close-expand {
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
  transition: all 1s !important;
}
