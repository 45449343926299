/* #region: ===== Mediascreen ================ */

// pakai min-width
$primary-mobile-width: 480px;
$primary-tablet-width: 768px; // 1 kolom
$primary-desktop-width: 1280px; // 3 kolom

$secondary-mobile-width: 360px; // [homepage] favorite menu 4 icon iphone 5s
$secondary-tablet-width: 600px; //  [gd-upload2-multiple, sf-upload2, sf-upload-image]
$secondary-desktop-width: 1024px; // [homepage] 2 kolom konten
$secondary-sidemenu-collapse: 1420px; // [AppMenuPage]sidemenu collapse

$terniary-desktop-width: 1366px; // login-demo

/* #endregion: == Mediascreen ================ */

// MIXIN //

@mixin position-setting($position, $top: null, $right: null, $bottom: null, $left: null) {
    position: $position;
    top: $top;
    left: $left;
    right: $right;
    bottom: $bottom;
}

@mixin shape-box($width: unset, $height: unset, $radius: 0) {
    width: $width;
    height: $height;
    border-radius: $radius;
}

@mixin font-setting($size, $color: unset, $weight: normal) {
    font-size: $size;
    color: $color;
    font-weight: $weight;
}
